<template>
  <div />
</template>
<script>
  export default {
    data: function () {
      return {
      }
    },
    mounted: function () {
      this.$msal.signOut()
    },
  }
</script>
